











































































































































































































































































































































import Vue from "vue";
import moment, { Moment } from "moment";
// interface
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseUploadData } from "@interface/file.interface";
import { RequestWarehouseTransferCreate, ResponseWarehouseTransfer } from "@interface/warehouse-transfer.interface";
import { ResponseListProduct } from "@interface/product.interface";
import { ResponseListWarehouseLocation, ResponseWarehouseLocation } from "@interface/warehouse.interface";
// service
import { fileServices } from "@service/file.service";
import { warehouseLocationService } from "@service/warehouse-location.service";
import { warehouseTransferService } from "@service/warehouse-transfer.service";
// constant
import { WAREHOUSE_STATE } from "@enum/warehouse-transfer.enum";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { MAXIMUM_FILE_SIZE } from "@constant/global.constant";
// utility
import { debounceProcess } from "@/helpers/debounce";
// vuex
import { createNamespacedHelpers } from "vuex";
import { IOption } from "@/models/interface-v2/common.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { inventoryLineBatchService } from "@/services-v2/inventory-line-batch.service";
import { ResponseListInventoryLineBatch } from "@/models/interface-v2/inventory-line-batch.interface";

const { mapState, mapMutations } = createNamespacedHelpers("batchingStore");

const FILENAME_INDEX = 57;

interface IRow {
  key: any;
  productId: string;
  productCode: string;
  productName: string;
  uomId: string;
  uom: string;
  qtyTotal: number;
  qtyReceived: number; // only for received
  qtyOutstanding: number; // only for received
  secureId: string; // get from detail transfer
  batchId: string; // get from generate qr
  batchNumber: string;
  destinationLocationId: string;
  destinationLocation: string;
}

export default Vue.extend({
  name: "WarehouseTransfer",
  mixins: [
    MNotificationVue,
  ],
  data() {
    this.searchProductCode = debounceProcess(this.searchProductCode, 300);
    this.searchLocationFrom = debounceProcess(this.searchLocationFrom, 300);
    this.searchLocationTo = debounceProcess(this.searchLocationTo, 300);
    return {
      DEFAULT_DATE_FORMAT,
      form: {
        documentNumber: "",
        from: undefined as string | undefined,
        to: undefined as string | undefined,
        transferDate: "",
        receiveDate: null as Moment | null,
        note: "",
        attachment: "",
        status: "",
        receivedTime: moment() as Moment | null,
        deletedLineIds: [] as string[],
      },
      rules: {
        from: [{ required: true, trigger: "change", message: this.$t("lbl_validation_required_error") }],
        to: [{ required: true, trigger: "change", message: this.$t("lbl_validation_required_error") }],
        transferDate: [{ required: true, trigger: "change", message: this.$t("lbl_validation_required_error") }],
        receiveDate: [{ required: true, trigger: "change", message: this.$t("lbl_validation_required_error") }],
        receivedTime: [{ required: true, trigger: "change", message: this.$t("lbl_validation_required_error") }],
      },
      rowProductLines: [] as IRow[],
      formRules: {
        documentNumber: {
          label: "lbl_document_number",
          name: "document number",
          placeholder: "lbl_type_here",
        },
        from: {
          label: "lbl_from",
          name: "From",
          placeholder: "lbl_type_here",
        },
        to: {
          label: "lbl_to",
          name: "To",
          placeholder: "lbl_type_here",
        },
        date: {
          label: "lbl_date",
          name: "Date",
          placeholder: "lbl_choose",
        },
        receiveDate: {
          label: "lbl_receive_date",
          name: "ReceiveDate",
          placeholder: "lbl_choose",
        },
        note: {
          label: "lbl_note",
          name: "Note",
          placeholder: "lbl_type_here",
        },
        attachment: {
          label: "lbl_attachment",
          name: "Attachment",
          placeholder: "lbl_choose",
        },
      },
      fileList: [] as any,
      selectedRowKeys: [] as number[],
      dtListLocationTo: [] as ResponseWarehouseLocation[],
      dtListProductCode: {} as ResponseListProduct,
      dtListProductName: {} as ResponseListProduct,
      optUom: [] as IOption[],
      optLocationFrom: [] as IOption[],
      optLocationTo: [] as IOption[],
      documentId: "" as string,
      detailTransfer: {
        state: WAREHOUSE_STATE.NEW,
      } as ResponseWarehouseTransfer,
      loading: {
        submit: false,
        draft: false,
        cancel: false,
        generate: false,
        locationFrom: false,
        locationTo: false,
        productCode: false,
        productName: false
      },
      documentAttachment: "javascript:void(0)" as string | ArrayBuffer | null,
      vmBatchNumber: ""
    };
  },
  computed: {
    ...mapState({
      storeBatching: (state: any) => state.theBatch
    }),
    isSubmitted(): boolean {
      return this.detailTransfer.state === WAREHOUSE_STATE.SUBMITTED;
    },
    isReceived(): boolean {
      return this.detailTransfer.state === WAREHOUSE_STATE.RECEIVED;
    },
    isDraft(): boolean {
      return this.detailTransfer.state === WAREHOUSE_STATE.DRAFT;
    },
    isNew(): boolean {
      return this.detailTransfer.state === WAREHOUSE_STATE.NEW;
    },
    isCancelled(): boolean {
      return this.detailTransfer.state === WAREHOUSE_STATE.CANCELLED;
    },
  },
  created() {
    this.getListLocation();
  },
  mounted() {
    if (this.$route.query.id) {
      this.documentId = this.$route.query.id as string;
      this.getDetailWarehouseTransfer(this.documentId);
    }
    if (this.$route.query.batching) {
      this.fillFromBatching();
    }
  },
  methods: {
    ...mapMutations([
      "SET_BATCHING_TRANSFER"
    ]),
    moment,
    updateWarehouseTransfer(id: string, payload: RequestWarehouseTransferCreate): Promise<ResponseWarehouseTransfer>{
      return warehouseTransferService.updateWarehouseTransfer(id, payload);
    },
    createWarehouseTransfer(payload: RequestWarehouseTransferCreate): Promise<ResponseWarehouseTransfer>{
      return warehouseTransferService.createWarehouseTransfer(payload);
    },
    submitWarehouseTransfer(id: string): Promise<ResponseWarehouseTransfer>{
      return warehouseTransferService.submitWarehouseTransfer(id);
    },
    receiveWarehouseTransfer(id: string, payload: RequestWarehouseTransferCreate): Promise<ResponseWarehouseTransfer>{
      return warehouseTransferService.receiveWarehouseTransfer(id, payload);
    },
    findBatchNumber(param: RequestQueryParamsModel): Promise<ResponseListInventoryLineBatch> {
      return inventoryLineBatchService.getListInventoryLineBatch(param);
    },
    validateTable(source: "transfer" | "received"): boolean {
      let valid = true;
      if (!this.rowProductLines.length) {
        valid = false;
      } else if (source === "transfer") {
        const idx = this.rowProductLines.findIndex(x => !x.productName || !x.productCode || !x.uomId || x.qtyTotal === 0);
        if (idx !== -1) valid = false;
      } else {
        const idx = this.rowProductLines.findIndex(x => !x.productName || !x.productCode || !x.uomId || x.qtyReceived === 0);
        if (idx !== -1) valid = false;
      }
      return valid;
    },
    submitDraft(): void {
      const formTransfer = this.$refs.formTransfer as any;
      formTransfer.validate((valid: boolean) => {
        if (valid && this.validateTable("transfer")) {
          this.saveAsDraft();
        } else {
          this.showNotifValidationError();
        }
      });
    },
    async saveAsDraft(): Promise<void> {
      try {
        this.loading.draft = true;
        const req: RequestWarehouseTransferCreate = {
          attachment: "",
          date: this.moment(this.form.transferDate).utcOffset("+07").format(),
          destinationLocationId: this.form.to || "",
          notes: this.form.note,
          sourceLocationId: this.form.from || "",
          receivedDate: "",
          transferLines: [],
          deletedLineIds: this.form.deletedLineIds,
        };
        // push products
        this.rowProductLines.forEach(item => {
          req.transferLines.push({
            destinationLocationId: item.destinationLocationId,
            batchId: item.batchId,
            productId: item.productId,
            totalQty: item.qtyTotal,
            uomId: item.uomId,
            secureId: item.secureId,
          });
        });
        if (this.fileList.length) {
          const resFile = await this.uploadFile(this.fileList[0].originFileObj);
          req.attachment = resFile.objectName;
        }
        if (this.documentId) {
          const res = await this.updateWarehouseTransfer(this.documentId, req);
          this.showNotifSuccess("notif_submit_success", {document: res.documentNumber});
          this.handleBack();
        } else {
          // create new warehouse transfer
          const res = await this.createWarehouseTransfer(req);
          this.showNotifSuccess("notif_document_created_as_draft_success", {documentNumber: res.documentNumber});
          this.handleBack();
        }
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.draft = false;
      }
    },
    /**
     * create new warehouse transfer
     * 1. create warehouse transfer
     * 2. submit warehouse transfer
     */
    async createNewWarehouseTransfer(): Promise<void> {
      try {
        this.loading.submit = true;
        const req: RequestWarehouseTransferCreate = {
          attachment: "",
          date: this.moment(this.form.transferDate).utcOffset("+07").format(),
          destinationLocationId: this.form.to || "",
          notes: this.form.note,
          sourceLocationId: this.form.from || "",
          receivedDate: "",
          transferLines: [],
          deletedLineIds: this.form.deletedLineIds
        };
        // push products
        this.rowProductLines.forEach(item => {
          req.transferLines.push({
            destinationLocationId: item.destinationLocationId,
            batchId: item.batchId,
            productId: item.productId,
            totalQty: item.qtyTotal,
            uomId: item.uomId,
          });
        });
        if (this.fileList.length) {
          const resFile = await this.uploadFile(this.fileList[0].originFileObj);
          req.attachment = resFile.objectName;
        }
        const res = await this.createWarehouseTransfer(req);
        await this.submitWarehouseTransfer(res.id);
        this.showNotifSuccess("notif_submit_success", { document: res.documentNumber });
        this.handleBack();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    async handleSubmitWarehouseTransfer(): Promise<void> {
      try {
        this.loading.submit = true;
        const res = await this.submitWarehouseTransfer(this.documentId);
        this.showNotifSuccess("notif_submit_success", { document: res.documentNumber });
        this.handleBack();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    async handleReceiveWarehouseTransfer(): Promise<void> {
      try {
        this.loading.submit = true;
        const detail = {...this.detailTransfer};
        const lines = [...this.rowProductLines];
        const req: RequestWarehouseTransferCreate = {
          attachment: detail.attachment,
          date: detail.date,
          destinationLocationId: detail.destinationLocationId,
          notes: detail.notes,
          sourceLocationId: detail.sourceLocationId,
          transferLines: [],
          receivedDate: this.form.receiveDate?.set({
            hour: this.form.receivedTime?.get("hour"),
            minute: this.form.receivedTime?.get("minute"),
            second: moment().get("second")
          }).format(),
          deletedLineIds: this.form.deletedLineIds
        };
        // push product lines
        detail.transferLines.forEach(item => {
          req.transferLines.push({
            destinationLocationId: item.destinationLocationId,
            batchId: item.batchNumberId,
            productId: item.product.id,
            receivedQty: item.qty,
            secureId: item.id,
            totalQty: item.qty,
            uomId: item.uom.id
          });
        });
        // push received qty
        lines.forEach((item, i) => {
          req.transferLines[i].receivedQty = item.qtyReceived;
        });
        const res = await this.receiveWarehouseTransfer(this.documentId, req);
        this.showNotifSuccess("notif_document_received_success", {
          documentNumber: res.documentNumber,
        });
        this.handleBack();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    handleSubmit(): void {
      const formTransfer = this.$refs.formTransfer as any;
      formTransfer.validate((valid: boolean) => {
        if (valid) {
          if (this.documentId) {
            // cek status
            if (this.isDraft && this.validateTable("transfer")) {
              // submit warehouse transfer
              this.handleSubmitWarehouseTransfer();
            } else if (this.isSubmitted && this.validateTable("received")) {
              // receive warehouse transfer
              this.handleReceiveWarehouseTransfer();
            } else {
              this.showNotifValidationError();
            }
          } else {
            this.createNewWarehouseTransfer();
          }
        } else {
          this.showNotifValidationError();
        }
      });
    },
    uploadFile(file): Promise<ResponseUploadData> {
      const formData = new FormData();
      formData.append("file", file);
      return fileServices.uploadFile(formData);
    },
    getListLocation() {
      let params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      this.loading.locationFrom = true;
      this.loading.locationTo = true;
      warehouseLocationService.listWarehouseLocation(params)
      .then((data: ResponseListWarehouseLocation) => {
        this.optLocationFrom = data.data.map(x => {
          return { key: x.locationCode, value: x.id };
        });
        this.optLocationTo = data.data.map(x => {
          return { key: x.locationCode, value: x.id };
        });
      })
      .finally(() => {
        this.loading.locationFrom = false;
        this.loading.locationTo = false;
      });
    },
    searchLocationFrom(valueSearch: string) {
      let params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };

      if (valueSearch) {
        params.search = `locationCode~*${valueSearch}*_OR_warehouse.branchWarehouse.name~*${valueSearch}*_OR_warehouse.branchWarehouse.code~*${valueSearch}*_OR_warehouse.branchWarehouse.address~*${valueSearch}*_OR_warehouse.name~*${valueSearch}*_OR_name~*${valueSearch}*`;
      }
      this.loading.locationFrom = true;
      warehouseLocationService.listWarehouseLocation(params)
      .then((data: ResponseListWarehouseLocation) => {
        this.optLocationFrom = data.data.map(x => {
          return { key: x.locationCode, value: x.id };
        });
      })
      .finally(() => (this.loading.locationFrom = false));
    },
    searchLocationTo(valueSearch: string) {
      let params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };

      if (valueSearch) {
        params.search = `locationCode~*${valueSearch}*_OR_warehouse.branchWarehouse.name~*${valueSearch}*_OR_warehouse.branchWarehouse.code~*${valueSearch}*_OR_warehouse.branchWarehouse.address~*${valueSearch}*_OR_warehouse.name~*${valueSearch}*_OR_name~*${valueSearch}*_OR_warehouse.code~*${valueSearch}*_OR_locationCode~*${valueSearch}*`;
      }
      this.loading.locationTo = true;
      warehouseLocationService.listWarehouseLocation(params)
      .then((data: ResponseListWarehouseLocation) => {
        this.optLocationTo = data.data.map(x => {
          return { key: `${x.locationCode}`, value: x.id };
        });
      })
      .finally(() => (this.loading.locationTo = false));
    },
    handleDeleteRow(): void {
      const { rowProductLines } = this;
      this.form.deletedLineIds = rowProductLines.filter(item => this.selectedRowKeys.includes(item.key)).map<string>((item) => item.secureId);
      this.rowProductLines = rowProductLines.filter((data) => {
        return !this.selectedRowKeys.includes(data.key);
      });
      this.rowProductLines.forEach((data, index) => (data.key = index));
      this.rowProductLines = this.rowProductLines.slice();
      this.selectedRowKeys = [];
    },
    showDeleteConfirmation(): void {
      if (this.selectedRowKeys.length > 0) {
        this.$confirm({
          title: this.$t("lbl_modal_delete_title_confirm"),
          content: this.$t("lbl_modal_delete_info", { count: this.selectedRowKeys.length }),
          onOk: () => {
            this.handleDeleteRow();
          },
        });
      } else {
        this.showNotifError("lbl_modal_delete_error_description");
      }
    },
    onRowSelect(selectedRowKeys): void {
      this.selectedRowKeys = selectedRowKeys;
    },
    beforeUpload(file: any) {
      const size = file.size;
      if (size >= MAXIMUM_FILE_SIZE) {
        this.$message.error(this.$t("lbl_upload_info_1").toString());
      }
      this.fileList = [...this.fileList, file];
      return false;
    },
    async handleRemove(file): Promise<void> {
      try {
        if (file.url) {
          const payload = {
            objectName: file.url.substr(FILENAME_INDEX, file.url.length), // file name start from index 57 from url string
            bucketName: "logistic",
          };
          await fileServices.deleteFile(payload.objectName);
          this.showNotifSuccess("notif_file_delete_success");
          this.fileList = [];
        } else {
          const index = this.fileList.indexOf(file);
          const newFileList = this.fileList.slice();
          newFileList.splice(index, 1);
          this.fileList = newFileList;
        }
      } catch (error) {
        this.showNotifError("notif_file_delete_fail");
      }
    },
    populateTable(): void {
      this.rowProductLines = [];
      this.detailTransfer.transferLines.forEach((item, i) => {
        this.rowProductLines.push({
          key: i,
          productId: item.product.id,
          productCode: item.product.code,
          productName: item.product.name,
          uomId: item.uom.id,
          uom: item.uom.unit,
          qtyTotal: item.qty || 0,
          qtyReceived: item.receivedQty || item.qty,
          qtyOutstanding: item.outstandingQty || 0,
          secureId: item.id,
          batchId: item.batchNumberId,
          batchNumber: item.batchNumber,
          destinationLocationId: item.destinationLocationId,
          destinationLocation:item.destinationLocation
        });
      });
    },
    async getDetailWarehouseTransfer(docId: string): Promise<void> {
      try {
        const res = await warehouseTransferService.detailWarehouseTransfer(docId);
        this.detailTransfer = res;
        this.form = {
          documentNumber: this.detailTransfer.documentNumber,
          from: this.detailTransfer.sourceLocationId,
          to: this.detailTransfer.destinationLocationId,
          transferDate: this.detailTransfer.date,
          receiveDate: this.detailTransfer.receivedDate ? moment(this.detailTransfer.receivedDate) : moment(),
          note: this.detailTransfer.notes,
          attachment: this.detailTransfer.attachment,
          status: this.detailTransfer.state,
          receivedTime: this.detailTransfer.receivedDate ? moment(this.detailTransfer.receivedDate) : moment(),
          deletedLineIds: [],
        };

        // fill missing location
        const locSource = this.optLocationFrom.find(x => x.value === this.form.from);
        if (!locSource) {
          this.optLocationFrom.push({key: this.detailTransfer.sourceLocation, value: this.detailTransfer.sourceLocationId});
        }

        // fill uom
        this.optUom = [];
        this.detailTransfer.transferLines.forEach(item => {
          this.optUom.push({key: item.uom.unit, value: item.uom.id});
          const locDestination = this.optLocationTo.find(x => x.value === item.destinationLocationId);
          if (!locDestination) {
            this.optLocationTo.push({key: item.destinationLocation, value: item.destinationLocationId});
          }
        });
        this.showAttachment();
        this.populateTable();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    async cancelForm(): Promise<void> {
      const confirm: boolean = await this.showConfirmation();
      if (!confirm) return;
      try {
        this.loading.cancel = true;
        await warehouseTransferService.cancelWarehouseTransfer(this.documentId);
        this.showNotifSuccess("notif_cancel_success");
        this.handleBack();
      } catch (error) {
        this.showNotifError("notif_cancel_fail");
      } finally {
        this.loading.cancel = false;
      }
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current > moment().endOf("day");
    },
    handleBack(): void {
      this.$router.push({ name: "logistic.warehousetransfer.list" });
    },
    async showAttachment(): Promise<void> {
      try {
        if (this.detailTransfer.attachment) {
          const blob = await fileServices.getFile(this.detailTransfer.attachment);
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => {
            this.documentAttachment = reader.result;
          };
        }
      } catch (error) {
        this.showNotifError("notif_file_get_fail", { filename: this.detailTransfer.attachment });
      }
    },
    async deleteAttachment(): Promise<void> {
      try {
        await fileServices.deleteFile(this.detailTransfer.attachment);
        const payloadUpdate: RequestWarehouseTransferCreate = {
          attachment: "", // send empty string means attachment is deleted
          date: this.detailTransfer.date,
          destinationLocationId: this.detailTransfer.destinationLocationId,
          notes: this.detailTransfer.notes,
          receivedDate: this.detailTransfer.receivedDate,
          sourceLocationId: this.detailTransfer.sourceLocationId,
          transferLines: [...this.detailTransfer.transferLines].map(item => {
            return {
              destinationLocationId: item.destinationLocationId,
              productId: item.product.id,
              totalQty: item.qty,
              uomId: item.uom.id,
              receivedQty: item.receivedQty,
              secureId: item.id,
              batchId: ""
            };
          }),
          deletedLineIds: this.form.deletedLineIds
        };
        await warehouseTransferService.updateWarehouseTransfer(this.documentId, payloadUpdate);
        await this.getDetailWarehouseTransfer(this.documentId);
        this.showNotifSuccess("notif_file_delete_success");
      } catch (error) {
        this.showNotifError("notif_file_delete_fail");
      }
    },
    async generateByBatchNumber(search = ""): Promise<void> {
      try {
        if (!search) return;
        this.loading.generate = true;
        const param = {
          search: `warehouseLocation.secureId~${this.form.from}_AND_batch.batchNumber~${search}_AND_available>0`
        };
        const { data } = await this.findBatchNumber(param);
        const [ batch ] = data;
        if (!batch) {
          this.showNotifError("notif_batch_is_not_found_in_current_location", {data: search});
          return;
        }

        const { rowProductLines } = this;
        const newRow: IRow = {
          key: this.rowProductLines.length,
          productId: batch.product.id,
          productCode: batch.product.code,
          productName: batch.product.name,
          uomId: batch.product.baseUnitId,
          uom: batch.product.baseUnit,
          qtyTotal: batch.available,
          qtyReceived: batch.available,
          qtyOutstanding: batch.available,
          secureId: "",
          batchId: batch.batchId,
          batchNumber: batch.batchNumber,
          destinationLocationId: "",
          destinationLocation:"",
        };

        // validation
        const reserved = rowProductLines.find(item => item.batchNumber === newRow.batchNumber);
        if (reserved) {
          this.loading.generate = false;
          this.showNotifWarning("notif_duplicate_item", {data: reserved.batchNumber});
          return;
        }

        this.rowProductLines = [...rowProductLines, newRow];
        // fill uom
        this.optUom = [];
        if (batch.product.uomConversions && batch.product.uomConversions.length) {
          batch.product.uomConversions.forEach(x => {
            this.optUom.push({ key: x.unitUom, value: x.unitUomId });
          });
        } else {
          this.optUom = [{ key: batch.product.baseUnit, value: batch.product.baseUnitId }];
        }
        this.vmBatchNumber = "";
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.generate = false;
      }
    },
    handleChangeAttachment(info): void {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      this.fileList = fileList;
    },
    fillFromBatching(): void {
      const batching = this.storeBatching;
      const loc = this.optLocationFrom.find(x => x.value === batching.sourceLocationId);
      if (!loc) {
        this.optLocationFrom.push({key: batching.sourceLocationName, value: batching.sourceLocationId});
      }
      this.form.from = batching.sourceLocationId;

      this.rowProductLines = batching.transferLines.map((x, i) => {
        return {
          key: i,
          productId: x.productId,
          productCode: x.productCode,
          productName: x.productName,
          uomId: x.uomId,
          uom: x.uom,
          batchId: x.batchId,
          batchNumber: x.batchNumber,
          qtyTotal: x.qty,
          qtyReceived: x.qty,
          qtyOutstanding: x.qty,
          secureId: "",
        };
      });
    },
    onChangeLocationFrom(): void {
      const rows = [...this.rowProductLines];
      this.form.deletedLineIds = rows.filter(item => !!item.secureId).map<string>(item => item.secureId);
      this.rowProductLines = [];
    },
  },
});
