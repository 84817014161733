var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_warehouse_transfer") } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form-model",
                {
                  ref: "formTransfer",
                  attrs: {
                    "label-align": "left",
                    rules: _vm.rules,
                    model: _vm.form,
                    "label-col": { sm: { span: 24 }, lg: { span: 5 } },
                    "wrapper-col": { sm: { span: 24 }, lg: { span: 19 } }
                  }
                },
                [
                  _c("a-form-model-item", [
                    _c("h4", { staticClass: "ant-form-text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isSubmitted
                              ? _vm.$t("lbl_receive_item")
                              : _vm.$t("lbl_transfer_item")
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.isSubmitted || _vm.isReceived || _vm.isDraft,
                          expression: "isSubmitted || isReceived || isDraft"
                        }
                      ],
                      attrs: {
                        label: _vm.$t(_vm.formRules.documentNumber.label)
                      }
                    },
                    [
                      _c("span", { staticClass: "ant-form-text" }, [
                        _vm._v(_vm._s(_vm.form.documentNumber || "-"))
                      ])
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.from.label),
                        prop: "from"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            name: _vm.formRules.from.name,
                            placeholder: _vm.$t(_vm.formRules.from.placeholder),
                            "show-search": true,
                            "filter-option": false,
                            loading: _vm.loading.locationFrom,
                            "allow-clear": true,
                            disabled:
                              _vm.isCancelled ||
                              _vm.isReceived ||
                              _vm.isSubmitted
                          },
                          on: {
                            search: function(value) {
                              return _vm.searchLocationFrom(value)
                            },
                            change: _vm.onChangeLocationFrom
                          },
                          model: {
                            value: _vm.form.from,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "from", $$v)
                            },
                            expression: "form.from"
                          }
                        },
                        _vm._l(_vm.optLocationFrom, function(data) {
                          return _c(
                            "a-select-option",
                            {
                              key: data.key,
                              attrs: {
                                value: data.value,
                                disabled: _vm.form.to === data.value
                              }
                            },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.key || "-") + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.key || "-") + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.date.label),
                        prop: "transferDate"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        staticClass: "w-100",
                        attrs: {
                          "disabled-date": _vm.disabledDate,
                          format: _vm.DEFAULT_DATE_FORMAT,
                          disabled: _vm.isCancelled || _vm.isReceived
                        },
                        model: {
                          value: _vm.form.transferDate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "transferDate", $$v)
                          },
                          expression: "form.transferDate"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.isSubmitted
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.receiveDate.label),
                            prop: "receiveDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            staticClass: "w-100",
                            attrs: {
                              "disabled-date": _vm.disabledDate,
                              format: _vm.DEFAULT_DATE_FORMAT,
                              disabled: _vm.isCancelled || _vm.isReceived
                            },
                            model: {
                              value: _vm.form.receiveDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "receiveDate", $$v)
                              },
                              expression: "form.receiveDate"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isSubmitted
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_received_time"),
                            prop: "receivedTime"
                          }
                        },
                        [
                          _c("a-time-picker", {
                            attrs: {
                              format: "HH:mm",
                              disabled: _vm.isCancelled || _vm.isReceived
                            },
                            model: {
                              value: _vm.form.receivedTime,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "receivedTime", $$v)
                              },
                              expression: "form.receivedTime"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t(_vm.formRules.note.label) } },
                    [
                      _c("a-textarea", {
                        attrs: {
                          name: _vm.formRules.note.name,
                          autocomplete: "off",
                          placeholder: _vm.$t(_vm.formRules.note.placeholder),
                          disabled: _vm.isCancelled || _vm.isReceived,
                          rows: 4,
                          "allow-clear": ""
                        },
                        model: {
                          value: _vm.form.note,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "note", $$v)
                          },
                          expression: "form.note"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.attachment.label),
                        help:
                          _vm.$t("lbl_upload_info_1") +
                          ". " +
                          _vm.$t("lbl_upload_info_3")
                      }
                    },
                    [
                      !_vm.detailTransfer.attachment
                        ? _c(
                            "a-upload",
                            {
                              attrs: {
                                name: _vm.formRules.attachment.name,
                                multiple: false,
                                "before-upload": _vm.beforeUpload,
                                remove: _vm.handleRemove,
                                "file-list": _vm.fileList,
                                accept: ".pdf, .png, .jpg, .xlsx",
                                disabled:
                                  _vm.isCancelled ||
                                  _vm.isReceived ||
                                  _vm.isSubmitted
                              },
                              on: { change: _vm.handleChangeAttachment }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.isCancelled ||
                                      _vm.isReceived ||
                                      _vm.isSubmitted
                                  }
                                },
                                [
                                  _c("a-icon", { attrs: { type: "upload" } }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm.formRules.attachment.placeholder
                                        )
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "span",
                            [
                              _c(
                                "a-space",
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.documentAttachment,
                                        download: _vm.detailTransfer.attachment,
                                        target: "_blank",
                                        rel: "noreferrer nooppener"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.detailTransfer.attachment
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm.isDraft
                                    ? _c("a-button", {
                                        attrs: {
                                          icon: "delete",
                                          size: "small"
                                        },
                                        on: { click: _vm.deleteAttachment }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_status") } },
                    [
                      _c("span", { staticClass: "ant-form-text" }, [
                        _vm._v(_vm._s(_vm.detailTransfer.state))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider", { attrs: { orientation: "left" } }, [
        _vm._v(" " + _vm._s(_vm.$t("lbl_detail")) + " ")
      ]),
      _c(
        "a-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isDraft || _vm.isNew,
              expression: "isDraft || isNew"
            }
          ],
          attrs: { gutter: [16, 16] }
        },
        [
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12 } },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.showDeleteConfirmation }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12 } },
            [
              _c(
                "a-input-search",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: _vm.$t("lbl_batch_number"),
                    "allow-clear": "",
                    disabled: !!!_vm.form.from
                  },
                  on: { search: _vm.generateByBatchNumber },
                  model: {
                    value: _vm.vmBatchNumber,
                    callback: function($$v) {
                      _vm.vmBatchNumber = $$v
                    },
                    expression: "vmBatchNumber"
                  }
                },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        slot: "enterButton",
                        type: "primary",
                        icon: "search",
                        disabled: !!!_vm.form.from
                      },
                      slot: "enterButton"
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_generate")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.rowProductLines,
                    "row-selection": {
                      selectedRowKeys: _vm.selectedRowKeys,
                      onChange: _vm.onRowSelect
                    },
                    pagination: false
                  }
                },
                [
                  _c(
                    "a-table-column",
                    {
                      key: "batchNumber",
                      attrs: { "data-index": "batchNumber" }
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_batch_number")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "productCode",
                      attrs: { "data-index": "productCode" }
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_code")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "productName",
                      attrs: { "data-index": "productName" }
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_name")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "uom",
                      attrs: { "data-index": "uom", width: "100px" }
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_uom")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "destinationLocationId",
                      attrs: { "data-index": "destinationLocationId" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm.isNew || _vm.isDraft
                                ? _c(
                                    "a-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        name: _vm.formRules.to.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.to.placeholder
                                        ),
                                        "show-search": true,
                                        "filter-option": false,
                                        loading: _vm.loading.locationTo,
                                        "allow-clear": true,
                                        disabled:
                                          _vm.isCancelled || _vm.isReceived,
                                        "dropdown-match-select-width": false
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.searchLocationTo(value)
                                        }
                                      },
                                      model: {
                                        value: record.destinationLocationId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            record,
                                            "destinationLocationId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "record.destinationLocationId"
                                      }
                                    },
                                    _vm._l(_vm.optLocationTo, function(data) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: data.value,
                                          attrs: { value: data.value }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.key || "-") +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.key || "-") +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(_vm._s(record.destinationLocation))
                                  ])
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_to")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "qtyTotal",
                      attrs: { "data-index": "qtyTotal" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toDecimalQty")(record.qtyTotal)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_total_qty")))
                      ])
                    ]
                  ),
                  _vm.isSubmitted || _vm.isReceived || _vm.isCancelled
                    ? _c(
                        "a-table-column",
                        {
                          key: "qtyReceived",
                          attrs: { "data-index": "qtyReceived" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(text, record) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("toDecimalQty")(
                                            record.qtyReceived
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2568689038
                          )
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_qty_received")))]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.isSubmitted || _vm.isReceived || _vm.isCancelled
                    ? _c(
                        "a-table-column",
                        {
                          key: "qtyOutstanding",
                          attrs: {
                            "data-index": "qtyOutstanding",
                            disabled: "true"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(text, record) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("toDecimalQty")(
                                            record.qtyOutstanding
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            278003459
                          )
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_qty_outstanding")))]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  !_vm.isCancelled
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            disabled: _vm.isReceived || _vm.isSubmitted,
                            loading: _vm.loading.draft || _vm.loading.submit
                          },
                          on: { click: _vm.submitDraft }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lbl_save_as_draft")) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  !_vm.isNew && !_vm.isCancelled
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            disabled: _vm.isReceived || _vm.isDraft,
                            loading:
                              _vm.loading.cancel ||
                              _vm.loading.draft ||
                              _vm.loading.submit,
                            type: "danger"
                          },
                          on: { click: _vm.cancelForm }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                      )
                    : _vm._e(),
                  !_vm.isCancelled
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            disabled: _vm.isReceived,
                            loading: _vm.loading.submit || _vm.loading.draft,
                            type: "primary"
                          },
                          on: { click: _vm.handleSubmit }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isDraft
                                  ? _vm.$t("lbl_submit")
                                  : _vm.isSubmitted
                                  ? _vm.$t("lbl_received")
                                  : _vm.$t("lbl_create")
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }